import {api, apiConfig} from "../../interceptors/api";
import {
  InitiateBuyNowPayload,
  InitiateBuyNowResponse,
  CartDetailsResponse,
  UpdateCartPayload,
  UpdateCartResponse,
  RemoveFromCartPayload,
  RemoveFromCartResponse,
} from "./CartInterface";

export const initiateBuyNowApi = async (
  payload: InitiateBuyNowPayload,
): Promise<InitiateBuyNowResponse> => {
  try {
    const response = await api.post(
      `${apiConfig.cartUrl}/api/cart/buyNow`,
      payload,
    );
    return response.data;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    throw new Error(
      error && error.response && error.response.data.error
        ? error.response.data.error
        : "Failed to update cart",
    );
  }
};

export const getCartApi = async (): Promise<CartDetailsResponse> => {
  try {
    const response = await api.get(`${apiConfig.cartUrl}/api/cart`);
    return response.data;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    throw new Error(
      error && error.response && error.response.data.error
        ? error.response.data.error
        : "Failed to fetch cart data",
    );
  }
};

export const updateCart = async (
  payload: UpdateCartPayload,
): Promise<UpdateCartResponse> => {
  try {
    const {cartId, ...rest} = payload;
    const response = await api.put<UpdateCartResponse>(
      `${apiConfig.cartUrl}/api/cart/${cartId}`,
      rest,
    );
    return response.data;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    throw new Error(
      error && error.response && error.response.data.error
        ? error.response.data.error
        : "Failed to update carts",
    );
  }
};

export const removeFromCart = async (
  payload: RemoveFromCartPayload,
): Promise<RemoveFromCartResponse> => {
  try {
    const response = await api.post(
      `${apiConfig.cartUrl}/api/cart/remove`,
      payload,
    );
    return response.data;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    throw new Error(
      error && error.response && error.response.data.error
        ? error.response.data.error
        : "Failed to remove from cart",
    );
  }
};

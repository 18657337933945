import React, {useEffect, useState} from "react";
import {setAuthentication} from "../../../features/Authentication/AuthenticationSlice";
import cookieUtils from "../../../utils/cookies";
import {AppDispatch, RootState} from "../../../interceptors/store";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate, useParams} from "react-router";
import {getOrderByOrderIdThunk} from "../../../features/Order/OrderSlice";
// import OrderDetailsCard from './components/OrderDetailsCard/OrderDetailsCard';
import ProductCard from "./components/ProductCard/ProductCard";
import {OrderItem} from "../../../features/Order/OrderInterface";
import OrderDetailsCard from "./components/OrderDetailsCard/OrderDetailsCard";
import OrderStatusCard from "./components/OrderStatusCard/OrderStatusCard";
import "./OrderDetailsPage.style.css";
import OrderDetailsPageSkeleton from "./components/Skeleton/OrderDetailsPageSkeleton";

const OrderDetailsPage: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(true);

  const {orderId} = useParams<{orderId: string}>(); // Get the `orderId` from the URL
  const {orderItems} = useSelector((state: RootState) => state.order);
  const {data} = useSelector((state: RootState) => state.order.order);

  useEffect(() => {
    if (cookieUtils.getCookie("token")) {
      dispatch(setAuthentication(true));
    } else {
      navigate("/");
    }
  }, [dispatch, navigate]);

  useEffect(() => {
    if (orderId) {
      getOrderDetails();
    }
  }, [dispatch, orderId, orderItems]);

  const getOrderDetails = async () => {
    setLoading(true);
    await dispatch(getOrderByOrderIdThunk({orderId: orderId ?? ""})); // Fetch order details
    setLoading(false);
  };

  return (
    <div className="order-details-page-container">
      {loading ? (
        // Skeleton loader while loading the orders
        Array.from({length: 5}).map((_, index) => (
          <OrderDetailsPageSkeleton key={`stream-${index}`} />
        ))
      ) : (
        <div>
          <OrderStatusCard
            orderItemCount={data?.order_item_details.length}
            orderNum={data?.order_id}
            buyerDetails={data?.buyer_details}
            currentStatus={data?.status}
          />
          <div className="order-details-page-product-card-ctn">
            {data?.order_item_details.map((item: OrderItem, index: number) => (
              <ProductCard
                key={index}
                productName={item.product_name}
                productDescription={item.product_description}
                productQuantity={item.quantity}
                productImage={
                  item.product_image_details[0] ||
                  "https://www.shutterstock.com/image-photo/head-shot-portrait-close-smiling-260nw-1714666150.jpg"
                }
                variants={item.variants}
              />
            ))}
          </div>

          <OrderDetailsCard
            totalItems={data?.order_item_details.length}
            priceIncludingGST={data?.total_item_amount}
            discount={
              (data?.total_item_amount ?? 0) - (data?.discounted_amount ?? 0)
            }
            deliveryCharge={data?.delivery_charges}
            orderTotal={data?.net_amount}
          />
        </div>
      )}
    </div>
  );
};

export default OrderDetailsPage;

import React from "react";
import "./OrderDetailsPageSkeleton.style.css";

const OrderDetailsPageSkeleton: React.FC = () => {
  return (
    <div
      className="order-status-card-container-skeleton"
      style={{width: "100%"}}>
      <div
        className="order-status-card-cnt-skeleton-content"
        style={{width: "100%", paddingLeft: "2%", marginBottom: "3%"}}>
        <div className="order-details-page-skeleton-text-h1 skeleton"></div>
        <div className="order-details-page-skeleton-text-h2 skeleton"></div>
        <div
          className="order-details-page-skeleton-text-h1 skeleton"
          style={{marginTop: "3%"}}></div>
        <div className="order-details-page-skeleton-text-h2 skeleton"></div>
        <div className="order-details-page-skeleton-text-h2 skeleton"></div>
      </div>
      <div
        className="order-details-page-product-card-ctn"
        style={{width: "100%"}}>
        <div
          className="orders-page-product-container"
          style={{
            width: "100%",
            marginTop: "5%",
            marginBottom: "5%",
            paddingLeft: "2%",
          }}>
          <div
            className="orders-page-left-container skeleton"
            style={{width: "25%"}}></div>
          <div className="orders-page-right-container" style={{width: "70%"}}>
            <div className="orders-page-right-container-top">
              <div className="skeleton-text skeleton"></div>
              <div className="skeleton-text skeleton"></div>
            </div>
            <div className="orders-page-button-container">
              <div className="orders-page-quantity-container">
                <div className="orders-page-quantity-box skeleton"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="order-details-page-card-container"
        style={{paddingLeft: "2%"}}>
        <div className="order-details-page-skeleton-text-h1 skeleton"></div>
        <div className="order-details-page-skeleton-text-h3 skeleton"></div>
        <div className="order-details-page-skeleton-text-h3 skeleton"></div>
        <div className="order-details-page-skeleton-text-h3 skeleton"></div>
        <div className="order-details-page-skeleton-text-h3 skeleton"></div>
        <div className="order-details-page-skeleton-text-h4 skeleton"></div>
      </div>
    </div>
  );
};

export default OrderDetailsPageSkeleton;

import React, {useState} from "react";
import ZoopLogo from "../../SvgImages/ZooLogo/ZoopLogo";
import BottomSheet from "../BottomSheet/BottomSheet";
import "./Header.style.css";
import cookieUtils from "../../../utils/cookies";
import Menu from "../Menu/Menu";
import {menuConfig} from "../../../constants/menuConfig";
import {useSelector} from "react-redux";
import {RootState} from "../../../interceptors/store";
import {useNavigate} from "react-router";

const Header: React.FC = () => {
  const [isLogoutBottomSheetOpen, setIsLogoutBottomSheetOpen] =
    useState<boolean>(false);

  const navigate = useNavigate();
  const {isSignedIn} = useSelector((state: RootState) => state.authentication);

  return (
    <>
      <div className="mobile-home-header">
        <div>
          <ZoopLogo height={100} width={100} />
        </div>
        <div style={{textAlign: "right"}}>
          {isSignedIn ? (
            <Menu
              menu={menuConfig}
              onClick={id => {
                console.log("id", id);
                if (id === "logout") {
                  setIsLogoutBottomSheetOpen(true);
                }
              }}
            />
          ) : (
            <div style={{textAlign: "right"}}>
              <button
                className="logout-button"
                onClick={() => {
                  navigate("/login");
                }}>
                Login
              </button>
            </div>
          )}
        </div>
      </div>
      <BottomSheet
        header="Logout"
        isOpen={isLogoutBottomSheetOpen}
        onClose={() => setIsLogoutBottomSheetOpen(false)}>
        <div className="header-logout-content-container">
          <p style={{color: "black"}}>Are you sure you want to logout?</p>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
              width: "100%",
              gap: "5%",
            }}>
            <div style={{width: "50%"}}>
              <button
                className="logout-yes-button"
                onClick={() => {
                  localStorage.clear();
                  cookieUtils.removeCookie("token");
                  window.location.href = "/";
                }}>
                Yes
              </button>
            </div>
            <div style={{width: "50%"}}>
              <button
                className="logout-no-button"
                onClick={() => setIsLogoutBottomSheetOpen(false)}>
                No
              </button>
            </div>
          </div>
        </div>
      </BottomSheet>
    </>
  );
};

export default Header;

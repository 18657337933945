import React from "react";
import "./ProductCard.style.css";
import {Variant} from "../../../../../features/Order/OrderInterface";

interface ProductCardProps {
  productName: string;
  productDescription: string;
  productQuantity: number;
  productImage: string;
  variants: Variant[][];
}

const ProductCard: React.FC<ProductCardProps> = ({
  productName,
  productDescription,
  productQuantity,
  productImage,
  variants,
}) => {
  return (
    <div className="product-card-container">
      <div className="product-container">
        {/* Left Section */}
        <div className="product-left-container">
          <img
            src={
              productImage ||
              "https://storage.googleapis.com/zoop-public-bucket/ZOOP-RECOMMENDS/productstock.png"
            }
            alt={productName}
            className="product-image"
          />
        </div>

        {/* Right Section */}
        <div className="product-right-container">
          <p className="product-name" title={productName}>
            {productName}
          </p>
          <p className="product-description" title={productDescription}>
            {productDescription}
          </p>

          <div className="button-container">
            <div className="quantity-container">
              <div className="dropdown-button-container">
                <span className="dropdown-button-text">
                  Quantity - {productQuantity}
                </span>
              </div>
            </div>

            {variants &&
              variants.length > 0 &&
              variants[0][0].label !== "No Variant" && (
                <div className="dropdown-button-container">
                  {variants[0].map((variant, index) => {
                    return (
                      <div
                        key={index}
                        className={`variant-container ${index > 1 ? "hidden" : ""}`}>
                        <span className="dropdown-button-text-order-details">
                          {variant?.label?.toLowerCase().includes("size")
                            ? "Size"
                            : variant.label}{" "}
                          - {variant.value}
                        </span>
                      </div>
                    );
                  })}
                </div>
              )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductCard;

/* eslint-disable @typescript-eslint/no-explicit-any */
import {createSlice, createAsyncThunk} from "@reduxjs/toolkit";

import {
  initiateBuyNowApi,
  getCartApi,
  updateCart,
  removeFromCart,
} from "./CartApi";
import {
  CartItemDetails,
  InitiateBuyNowPayload,
  UpdateCartPayload,
  RemoveFromCartPayload,
  RemoveFromCartResponse,
} from "./CartInterface";
import {findIndexByKey} from "../../utils/helpers";
export interface CartState {
  loading: boolean;
  error: any;
  success: boolean;
  cartDetails: CartItemDetails[];
}

const initialState: CartState = {
  loading: false,
  error: null,
  success: false,
  cartDetails: [],
};

export const initiateBuyNowThunk = createAsyncThunk(
  "cart/buyNow",
  async (payload: InitiateBuyNowPayload, {rejectWithValue}) => {
    try {
      const response = await initiateBuyNowApi(payload);
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  },
);

// Thunk for fetching cart details
export const getCartDetailsThunk = createAsyncThunk<
  {user_id: string; cartDetails: CartItemDetails[]}, // resolved return type
  void, // no payload
  {rejectValue: string} // rejection type
>("cart/cartDetails", async (_, {rejectWithValue}) => {
  try {
    const response = await getCartApi(); // make the API call
    return response.data; // assuming the response data is structured as { user_id, cartDetails }
  } catch (error: any) {
    return rejectWithValue(error.message); // reject with a string error message
  }
});

export const updateCartItemThunk = createAsyncThunk(
  "cart/updateCartItem",
  async (payload: UpdateCartPayload, {rejectWithValue}) => {
    try {
      const response = await updateCart(payload);

      return response.data;
    } catch (err: any) {
      return rejectWithValue(err.message);
    }
  },
);

export const removeItemFromCartThunk = createAsyncThunk(
  "cart/removeItemFromCart",
  async (payload: RemoveFromCartPayload, {rejectWithValue}) => {
    try {
      const response: RemoveFromCartResponse = await removeFromCart(payload);
      return response.data;
    } catch (err: any) {
      return rejectWithValue(err);
    }
  },
);

const cartSlice = createSlice({
  name: "cart",
  initialState,
  reducers: {
    reset: () => initialState,
  },
  extraReducers: builder => {
    builder
      .addCase(initiateBuyNowThunk.pending, state => {
        state.loading = true;
        state.error = null;
        state.success = false;
      })
      .addCase(initiateBuyNowThunk.fulfilled, state => {
        state.loading = false;

        state.error = null;
      })
      .addCase(initiateBuyNowThunk.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
        state.success = false;
      })
      //get cart
      .addCase(getCartDetailsThunk.pending, state => {
        state.loading = true;
        state.error = null;
        state.success = false;
      })
      .addCase(getCartDetailsThunk.fulfilled, (state, action) => {
        state.loading = false;
        state.cartDetails = action.payload.cartDetails;
        state.error = null;
      })
      .addCase(getCartDetailsThunk.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
        state.success = false;
      })
      //update cart
      .addCase(updateCartItemThunk.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateCartItemThunk.fulfilled, (state, action) => {
        state.loading = false;

        const updatedCart = action.payload;
        if (updatedCart && updatedCart.cartId) {
          const index = findIndexByKey(
            state.cartDetails,
            "cartId",
            updatedCart.cartId,
          );
          if (index !== -1) {
            state.cartDetails[index] = updatedCart;
          }
        }

        state.error = null;
      })
      .addCase(updateCartItemThunk.rejected, (state, action) => {
        state.loading = false;
        state.error = action?.payload ?? "Failed to update item in cart";
      })

      .addCase(removeItemFromCartThunk.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(removeItemFromCartThunk.fulfilled, (state, action) => {
        state.loading = false;

        const removedCart = action.payload;
        if (removedCart && removedCart.cartId) {
          state.cartDetails = state.cartDetails.filter(
            item => item.cartId !== Number(removedCart.cartId),
          );
        }

        state.error = null;
      })
      .addCase(removeItemFromCartThunk.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload ?? "Failed to remove item from cart";
      });

    //remove cart
  },
});

export default cartSlice.reducer;
export const {reset} = cartSlice.actions;

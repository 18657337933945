import React, {lazy, Suspense, useEffect} from "react";
import {
  Routes,
  Route,
  useNavigate,
  useLocation,
  matchPath,
} from "react-router-dom";
import useMobileSize from "./hooks/useMobileSize";
import "./App.css";
import {toast} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {useDispatch, useSelector} from "react-redux";
import "promise-polyfill/src/polyfill";
import {getUserThunk} from "./features/User/UserSlice";
import {setAuthentication} from "./features/Authentication/AuthenticationSlice";
import ReactGA from "react-ga4";
import {getLiveStreamsThunk} from "./features/LiveStream/LiveStreamSlice";
import {AppDispatch, RootState} from "./interceptors/store";
import OrderDetailsPage from "./pages/mobile/OrderDetailsPage/OrderDetailsPage";

import Header from "./components/mobile/Header/Header";
import cookieUtils from "./utils/cookies";

const excludeHeader: string[] = ["/login", "/stream/:id/live"];

// Initialize Google Analytics with your Tracking ID
ReactGA.initialize(process.env.REACT_APP_TRACKING_NUMBER);

// Use React.lazy to import components
const MobileHome = lazy(() => import("./pages/mobile/Home/MobileHome"));
const DesktopHome = lazy(() => import("./pages/Home"));
const Stream = lazy(() => import("./pages/mobile/Stream/Stream"));
const Login = lazy(() => import("./pages/mobile/Login"));
const StreamVideo = lazy(
  () => import("./pages/mobile/StreamVideo/StreamVideo"),
);
const PaymentConfirmation = lazy(
  () => import("./pages/mobile/PaymentConfirmation/PaymentConfirmation"),
);
const ProductDetail = lazy(
  () => import("./pages/mobile/ProductDetail/ProductDetail"),
);
const OrdersPage = lazy(() => import("./pages/mobile/OrdersPage/OrdersPage"));
const CartPage = lazy(() => import("./pages/mobile/Cart/Cart"));

const App: React.FC = () => {
  const isMobile = useMobileSize(1000);
  const dispatch = useDispatch<AppDispatch>();
  const {isSignedIn} = useSelector((state: RootState) => state.authentication);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    onPageLoad();
  }, []);

  const onPageLoad = () => {
    if (cookieUtils.getCookie("token")) {
      dispatch(setAuthentication(true));
    }
    fetchLiveStreams();
  };

  const fetchLiveStreams = async () => {
    try {
      const action = await dispatch(getLiveStreamsThunk({}));
      if (getLiveStreamsThunk.fulfilled.match(action)) {
        // Success handling here
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    if (isSignedIn) {
      fetchUser();
    }
    ReactGA.send({
      hitType: "pageview",
      page: location.pathname + location.search,
      isSignedIn,
      isMobile,
      userId: localStorage.getItem("userId") || null,
    });
  }, [isSignedIn]);

  const fetchUser = async () => {
    try {
      const action = await dispatch(getUserThunk());
      if (getUserThunk.fulfilled.match(action)) {
        const data = action.payload?.user;

        if (
          data &&
          data.first_name &&
          data.last_name &&
          data.email &&
          data.user_name
        ) {
          localStorage.setItem("username", data?.user_name);
          localStorage.setItem("showChat", "true");
          localStorage.setItem("switchMobileView", "true");
          if (location.pathname === "/") {
            navigate("/");
          }
        }
      } else {
        toast.error("Error fetching user");
        dispatch(setAuthentication(false));
      }
    } catch {
      toast.error("Error fetching user");
      dispatch(setAuthentication(false));
    }
  };

  // Check if none of the paths in excludeHeader match the current pathname
  const shouldRenderHeader = !excludeHeader.some(path =>
    matchPath({path, end: true}, location.pathname),
  );

  return (
    <Suspense
      fallback={
        <div style={styles.container}>
          <h2>Loading...</h2>
        </div>
      }>
      {shouldRenderHeader && <Header key="header-menu" />}

      <Routes>
        {!isMobile ? (
          <>
            <Route path="/" element={<DesktopHome />} />
          </>
        ) : (
          <>
            <Route path="/" element={<MobileHome />} />
            <Route path="/login" element={<Login />} />
            <Route path="/stream/:id" element={<Stream />} />
            <Route path="/stream/:id/live" element={<StreamVideo />} />
            <Route path="/payment" element={<PaymentConfirmation />} />
            <Route path="/product/:id" element={<ProductDetail />} />
            <Route path="/orders" element={<OrdersPage />} />
            <Route path="/cart" element={<CartPage />} />
            <Route path="/orders/:orderId" element={<OrderDetailsPage />} />
            <Route path="*" element={<MobileHome />} />
          </>
        )}
      </Routes>
    </Suspense>
  );
};

export default App;

const styles: {[key: string]: React.CSSProperties} = {
  container: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100vh",
    background: "linear-gradient(135deg, #f8f9fa, #e9ecef)",
  },
};

export interface MenuConfig {
  label: string;
  path: string;
  id: string;
}

export const menuConfig: MenuConfig[] = [
  {
    id: "home",
    label: "Home",
    path: "/",
  },
  {
    id: "orders",
    label: "Orders",
    path: "/orders",
  },
  {
    id: "cart",
    label: "Cart",
    path: "/cart",
  },
  {
    id: "support",
    label: "Support",
    path: "https://www.zooplive.com/contact",
  },
  {
    id: "logout",
    label: "Logout",
    path: "#",
  },
];

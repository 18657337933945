import {configureStore} from "@reduxjs/toolkit";
import AuthenticationSlice from "../features/Authentication/AuthenticationSlice";
import LiveStreamSlice from "../features/LiveStream/LiveStreamSlice";
import UserSlice from "../features/User/UserSlice";
import CartSlice from "../features/Cart/CartSlice";
import OrderSlice from "../features/Order/OrderSlice";

export const store = configureStore({
  reducer: {
    // add all reducers
    authentication: AuthenticationSlice,
    user: UserSlice,
    cart: CartSlice,
    liveStream: LiveStreamSlice,
    order: OrderSlice,
  },
});

// // and if you need the type of the store itself
export type AppStore = typeof store;

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;

export interface ApiConfig {
  /**
   * The URL of the api.
   */
  url: string;
  authUrl: string;
  userUrl: string;
  inventoryUrl: string;
  liveStreamUrl: string;
  notificationUrl: string;
  storageUrl: string;

  /**
   * Milliseconds before we timeout the request.
   */
  timeout: number;
}

import sign from "jwt-encode";
import Config from "../app/config";
import moment from "moment";
import cookieUtils from "./cookies";
import {toast} from "react-toastify";

export const logoutUser = async () => {
  toast.error("Your authentication has expired");
  setTimeout(() => {
    localStorage.clear();
    cookieUtils.removeCookie("token");
    window.location.href = "/";
  }, 2000);
};

/**
 *
 * @param array
 * @param key
 * @param value
 * @returns
 */
export const findIndexByKey = (array, key, value) => {
  for (let i = 0; i < array.length; i++) {
    if (array[i][key] == value) {
      return i;
    }
  }
  return -1;
};

/**
 *
 * @param {*} timestamp
 * @returns
 */
export const formatTimestamp = timestamp => {
  const date = moment(timestamp);
  const formattedDate = date.format("dddd DD MMMM, h:mm A");
  return formattedDate;
};

/**
 * Method to convert number into Indian Rupees.
 * @param {*} number
 */
export const convertIntoINR = number => {
  if (!number) {
    return number ? number.toString() : ""; // Assuming the input number is stringified if falsy
  }

  number = parseFloat(number.toString());

  const ans = number.toLocaleString("en-IN", {
    style: "currency",
    currency: "INR",
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  });
  return ans.substring(1);
};

export const convertTimestamp = (
  timestamp,
  isDotRemove = false,
  shouldIncludeTime = true,
) => {
  if (!timestamp) {
    return "";
  }

  const dateObj = new Date(timestamp);

  const day = dateObj.getDate();
  const month = dateObj.toLocaleString("default", {month: "short"});
  const year = dateObj.getFullYear().toString().slice(-2);
  let hours = dateObj.getHours();
  const ampm = hours >= 12 ? "PM" : "AM";
  hours = hours % 12;
  hours = hours || 12; // Handle midnight
  const minutes = dateObj.getMinutes().toString().padStart(2, "0");

  return `${day} ${month} ${year}${
    shouldIncludeTime
      ? ` ${isDotRemove ? " " : "•"} ${hours}:${minutes} ${ampm}`
      : ""
  }`;
};

export const convertTimestampIntoTime = timestamp => {
  if (!timestamp) {
    return "";
  }

  const dateObj = new Date(timestamp);

  let hours = dateObj.getHours();
  const ampm = hours >= 12 ? "PM" : "AM";
  hours = hours % 12;
  hours = hours || 12; // Handle midnight
  const minutes = dateObj.getMinutes().toString().padStart(2, "0");

  return `${hours}:${minutes} ${ampm}`;
};

export const getAddressStringFromObject = address => {
  return `${address?.address_details_line1}, ${address?.address_details_line2}, ${address?.city}, ${address?.pincode}, ${address?.state}`;
};

export const findByKey = (array, key, value) => {
  for (const element of array) {
    if (element[key] == value) {
      return element;
    }
  }
  return null;
};

export const generatePublicToken = () => {
  const jwtSignedToken = sign(
    {
      timestamp: Date.now(),
      type: "public",
      role: "buyer",
      scope: "read",
      origin: "website",
    },
    Config.JWT_PUBLIC_KEY,
    {
      alg: "HS256",
    },
  );

  return jwtSignedToken;
};

export const getSecondsPassed = createdAt => {
  if (!createdAt) {
    return 100;
  }

  const createdAtTime = new Date(createdAt);
  const currentTime = new Date();

  // Calculate the difference in milliseconds
  const timeDifference = currentTime - createdAtTime;

  // Convert the difference to seconds
  return Math.floor(timeDifference / 1000);
};

/**
 *
 * @param {*} details store front details
 * @returns calculate delivery charge
 */
export const calculateDeliveryCharges = async (store_details, cartProduct) => {
  if (store_details == null) {
    return 0;
  }

  const deliveryDiscount = store_details.delivery_discount
    ? parseInt(store_details.delivery_discount.toString())
    : 0;
  const freeDeliveryLowerLimit = store_details.free_delivery_amount
    ? parseInt(store_details.free_delivery_amount.toString())
    : 1000000000;
  const genericDeliveryFee = store_details.generic_delivery_fee
    ? parseInt(store_details.generic_delivery_fee.toString())
    : 0;
  let tempDeliveryCharge = 0;

  const totalProductPrice = cartProduct?.products.reduce(
    (sum, product) => sum + parseFloat(product.price),
    0,
  );

  if (parseInt(totalProductPrice?.toString())) {
    if (
      parseInt(totalProductPrice?.toString()) <
      parseInt(freeDeliveryLowerLimit?.toString())
    ) {
      tempDeliveryCharge = genericDeliveryFee - deliveryDiscount;
    }
  }
  return tempDeliveryCharge || 0;
};

export const groupProductsByStore = products => {
  // Object to store the grouped products by store id
  const groupedStores = {};

  products.forEach(product => {
    const storeId = product.seller_store_id;

    // If the store is not already in the groupedStores object, add it
    if (!groupedStores[storeId]) {
      groupedStores[storeId] = {
        store_id: storeId,
        store_name: product.store_name,
        store_profile_url: product.store_profile_url || "", // Use an empty string if no URL is present
        products: [],
      };
    }

    // Push the current product into the appropriate store's product list
    groupedStores[storeId].products.push({
      id: product.id,
      cartId: product.cartId,
      product_id: product.product_id,
      product_pricing_details_id: product.product_pricing_details_id,
      quantity: product.quantity,
      price: product.price,
      product_price: product.product_price,
      product_selling_price: product.product_selling_price,
      product_name: product.product_name,
      product_description: product.product_description,
      image: product.image,
      gst: product.gst,
      weight: product.weight,
      seller_id: product.seller_id,
      cart_delivery_address_id: product.cart_delivery_address_id,
      variants: product.variants,
    });
  });

  // Convert the groupedStores object to an array
  return Object.values(groupedStores);
};

// Convert the price to INR (Indian Rupee)
export const convertIntoINRV2 = price => {
  const numberPrice = Number(price);

  // Format the price in INR currency style
  const formattedPrice = new Intl.NumberFormat("en-IN", {
    style: "currency",
    currency: "INR",
  }).format(numberPrice);

  // Remove decimal part if it's ".00"
  return formattedPrice.endsWith(".00")
    ? formattedPrice.slice(0, -3)
    : formattedPrice;
};

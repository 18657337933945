/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */

import {api, apiConfig} from "../../interceptors/api";
import {
  GetCityStateByPincodePayload,
  GetCityStateByPincodeResponse,
  GetStoreFrontDetailsResponse,
} from "./UserInterface";

export const getUserData = async () => {
  try {
    const response = await api.get(`${apiConfig.userUrl}/api/user/buyer`);
    return response.data;
  } catch (error) {
    throw new Error("Failed to fetch departments data");
    // Add more API functions as needed
  }
};

export const updateUserData = async (payload: any) => {
  try {
    const respose = await api.patch(
      `${apiConfig.userUrl}/api/user/update_buyer_details`,
      payload,
    );
    return respose.data;
  } catch (error: any) {
    throw new Error("Failed to update user info");
  }
};

export const getCityStateByPincode = async (
  payload: GetCityStateByPincodePayload,
): Promise<GetCityStateByPincodeResponse> => {
  try {
    const respose = await api.get(
      `${apiConfig.userUrl}/api/user/pincode/${payload.pincode}`,
    );
    return respose.data;
  } catch (error: any) {
    throw new Error(error);
  }
};

export const getStoreFrontDetailsApi = async (
  storeId: string,
  type: string,
): Promise<GetStoreFrontDetailsResponse> => {
  try {
    const response = await api.get(
      `${apiConfig.userUrl}/api/store/store_front`,
      {
        params: {store_id: storeId, type},
      },
    );
    return response.data;
  } catch (error) {
    throw new Error("Failed to fetch store front details");
  }
};

/* eslint-disable @typescript-eslint/no-explicit-any */
import {createSlice, createAsyncThunk} from "@reduxjs/toolkit";
import {getOrderByOrderIdApi, getOrdersApi, getRawOrdersApi} from "./OrderApi";
import {GetOrdersPayload, OrderState} from "./OrderInterface";

const initialState: OrderState = {
  loading: false,
  error: null,
  success: false,
  orderItems: {
    data: [],
    page: 1,
    limit: 5,
    count: 0,
  },
  order: {
    data: null,
    loading: false,
  },
};

export const getOrdersThunk = createAsyncThunk(
  "order/getOrders",
  async (payload: GetOrdersPayload, {rejectWithValue}) => {
    try {
      const response = await getOrdersApi(payload);
      return response;
    } catch (error: any) {
      return rejectWithValue(error);
    }
  },
);

export const getRawOrdersThunk = createAsyncThunk(
  "order/getRawOrders",
  async (payload: GetOrdersPayload, {rejectWithValue}) => {
    try {
      const response = await getRawOrdersApi(payload);
      return response;
    } catch (error: any) {
      return rejectWithValue(error);
    }
  },
);

export const getOrderByOrderIdThunk = createAsyncThunk(
  "order/getOrdeItemDetails",
  async ({orderId}: {orderId: string}, {rejectWithValue}) => {
    try {
      const response = await getOrderByOrderIdApi(orderId);
      return response;
    } catch (error: any) {
      console.log("error", error);
      return rejectWithValue("Failed to fetch order by order id");
    }
  },
);

const orderSlice = createSlice({
  name: "order",
  initialState,
  reducers: {
    reset: () => initialState,
  },
  extraReducers: builder => {
    builder
      // Get Orders
      .addCase(getOrdersThunk.pending, state => {
        state.loading = true;
        state.error = null;
        state.success = false;
      })
      .addCase(getOrdersThunk.fulfilled, (state, action) => {
        state.loading = false;

        const {data} = action.payload;

        if (data?.orders?.[0] && data?.orders?.[0].order_id) {
          // Replace the data for the current page
          state.orderItems = {
            ...state.orderItems,
            data: data.orders, // Overwrite orders completely
            count: data.count,
            page: data.page,
            limit: data.limit,
          };
        }

        state.error = null;
      })

      // .addCase(getOrdersThunk.fulfilled, (state, action) => {
      //   state.loading = false;

      //   const {data} = action.payload;
      //   if (data?.orders?.[0] && data?.orders?.[0].order_id) {
      //     state.orderItems = {
      //       ...state.orderItems,
      //       data:
      //         data.page === 1
      //           ? data.orders
      //           : [...state.orderItems.data, ...data.orders],
      //       count: data.count,
      //       page: data.page,
      //       limit: data.limit,
      //     };
      //   }

      //   state.error = null;
      // })
      .addCase(getOrdersThunk.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(getRawOrdersThunk.pending, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(getRawOrdersThunk.fulfilled, state => {
        state.loading = false;
        state.error = null;
      })
      .addCase(getRawOrdersThunk.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      // Get order by order id
      .addCase(getOrderByOrderIdThunk.pending, state => {
        state.order.loading = true;
        state.error = null;
        state.success = false;
      })
      .addCase(getOrderByOrderIdThunk.fulfilled, (state, action) => {
        state.order.loading = false;

        const {data} = action.payload;
        if (data?.orders?.[0]) {
          state.order.data = data.orders[0];
        } else {
          state.order.data = null;
        }

        state.error = null;
      })
      .addCase(getOrderByOrderIdThunk.rejected, (state, action) => {
        state.order.loading = false;
        state.error = action.payload;
      });
  },
});

export default orderSlice.reducer;

import React from "react";
import {BuyerDetails} from "../../../../../features/Order/OrderInterface";
import "./OrderStatusCard.style.css";

interface OrderStatusCardProps {
  orderItemCount: number | undefined;
  orderNum?: string;
  buyerDetails?: BuyerDetails;
  currentStatus?: string;
}

const OrderStatusCard: React.FC<OrderStatusCardProps> = ({
  orderItemCount = 0,
  orderNum,
  buyerDetails,
  currentStatus,
}) => {
  return (
    <div className="order-status-card-container">
      <div className="order-status-card-header">
        {orderItemCount} {orderItemCount === 1 ? "Item" : "Items"}{" "}
        {currentStatus}
      </div>
      <div className="order-status-card-order-id">#{orderNum}</div>
      <div className="order-status-card-buyer-name">
        {buyerDetails?.buyer_name}
      </div>
      <div className="order-status-card-buyer-address">
        {buyerDetails?.buyer_address}
      </div>
    </div>
  );
};

export default OrderStatusCard;
